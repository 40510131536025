/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Carousel } from 'src/components/core/carousel';
import { Container } from 'src/components/core/layout/container';
import { ReactNode, useCallback, useState } from 'react';
import { Title } from 'src/components/layout/homepage/styles';
import { media } from 'src/styles/utils/media';
import Swiper from 'swiper';
import buttonIcon from 'src/assets/svg/button.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  buttonLabel: string;
  buttonUrl: string;
  children: ReactNode | ReactNode[];
  className?: string;
  slidesLength: number;
  title: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  color: var(--color-gray700);
  overflow-x: clip;
  padding-bottom: var(--vertical-spacing);
  position: relative;
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Title)`
  margin-bottom: 1rem;
`;

/**
 * `StyledTitle` styled component.
 */

const StyledTitle = styled(Title)`
  margin-bottom: 1rem;

  ${media.min.sm`
    padding-right: 2rem;
  `}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  ${media.min.ms`
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-template-rows: min-content;
  `}
`;

/**
 * `Flex` styled component.
 */

const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: min(100px, 10vmax);
`;

/**
 * `StyledCarousel` styled component.
 */

const StyledCarousel = styled(Carousel)`
  cursor: url('/images/cursor-drag.png') 33 33, grab;
  overflow: visible;
  user-select: none;

  ${media.min.sm`
    margin-left: 30%;
  `}

  .swiper-wrapper {
    cursor: url('/images/cursor-drag.png') 33 33, grab !important;
  }
`;

/**
 * Export `GalleryCarousel` component.
 */

export function GalleryCarousel(props: Props) {
  const { buttonLabel, buttonUrl, children, slidesLength, title, ...rest } =
    props;

  const [counter, setCounter] = useState<number>(1);
  const handleSlideChange = useCallback(({ activeIndex }: Swiper) => {
    setCounter(activeIndex + 1);
  }, []);

  return (
    <Wrapper {...rest}>
      <Container>
        <Header>
          <StyledTitle
            as={'h2'}
            data-scroll={'fade-in-up'}
            data-scroll-offset={'20%'}
          >
            {title}
          </StyledTitle>

          <Lead
            data-scroll={'clip-left'}
            data-scroll-animation-delay={300}
            data-scroll-offset={'20%'}
          >
            {`${counter} / ${slidesLength}`}
          </Lead>
        </Header>

        <StyledCarousel
          animation={'slide'}
          onActiveIndexChange={handleSlideChange}
        >
          {children}
        </StyledCarousel>

        <Flex>
          <Button
            colorTheme={'greenOutlined'}
            data-scroll={'fade-in-up'}
            data-scroll-offset={'20%'}
            href={buttonUrl}
            icon={buttonIcon}
            style={{ marginTop: 1 }}
          >
            {buttonLabel}
          </Button>
        </Flex>
      </Container>
    </Wrapper>
  );
}
