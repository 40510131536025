/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { Image } from 'src/components/core/image';
import { Magazine } from 'src/types/magazine';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { Text, textStyles } from 'src/components/core/text';
import { Title } from 'src/components/layout/homepage/styles';
import { formatDate } from 'src/core/utils/date';
import { ifProp, prop } from 'styled-tools';
import { media } from 'src/styles/utils/media';
import { useBreakpoint } from '@untile/react-components/dist/hooks';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useTranslation } from 'next-i18next';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import buttonIcon from 'src/assets/svg/button.svg';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  items: Magazine[];
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  padding-bottom: var(--vertical-spacing);
  position: relative;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled(Container)`
  display: grid;
  grid-template-areas: 'title' 'lead' 'viewAll' '.' 'listItems';
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, max-content) var(--vertical-spacing) max-content;
  position: relative;

  ${media.min.sm`
    grid-template-areas: 'title .' 'lead viewAll' '. .' 'listItems listItems';
    grid-template-columns: 6fr 4fr;
    grid-template-rows: repeat(2, max-content) var(--vertical-spacing) max-content;
  `}
`;

/**
 * `ViewAllButton` styled component.
 */

const ViewAllButton = styled(Button).attrs({
  colorTheme: 'greenOutlined',
  icon: buttonIcon
})`
  grid-area: viewAll;
  margin-top: 24px;
  width: max-content;

  ${media.min.sm`
    height: max-content;
    justify-self: end;
  `}
`;

/**
 * `ListItems` styled component.
 */

const ListItems = styled.div`
  display: grid;
  grid-area: listItems;
  grid-template-areas: 'item0' 'item1' 'item2' 'item3';
  grid-template-columns: 1fr;

  ${media.min.ms`
    grid-template-areas: 'item0 . item1' 'item0 . item2' 'item0 . item3';
    grid-template-columns: 4fr 1fr 5fr;
  `}
`;

/**
 * `ItemDate` styled component.
 */

const ItemDate = styled.span`
  ${textStyles.paragraph}

  display: block;
`;

/**
 * `ItemImage` styled component.
 */

const ItemImage = styled.div`
  overflow: hidden;
  padding-top: 54%;
  position: relative;
`;

/**
 * `ItemGrid` styled component.
 */

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px 0;

  ${media.min.sm`
    grid-template-columns: 1fr max-content;
    padding: 20px 8px 16px;
  `}
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.article<{ gridArea: string; isLast: boolean }>`
  grid-area: ${prop('gridArea')};

  :not(:first-child) {
    border-top: 1px solid var(--color-gray500);
  }

  ${ifProp(
    'isLast',
    css`
      border-bottom: 1px solid var(--color-gray500);
    `
  )}
`;

/**
 * `Arrow` styled component.
 */

const Arrow = styled(Svg)`
  transition: var(--transition-default);
  transition-property: color, transform;
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(RouterLink)`
  color: var(--color-gray700);
  transition: color var(--transition-default);

  :hover,
  :focus {
    color: var(--color-sage700);

    ${Arrow} {
      transform: translateX(8px);
    }

    ${ItemDate} {
      color: var(--color-gray700);
    }
  }
`;

/**
 * `BlurImage` styled component.
 */

const BlurImage = styled(Image)`
  transform: translate(0, -24px);

  ${media.min.md`
    transform: translate(0, -15%);
  `}
`;

/**
 * Export `MagazineHighlights` component.
 */

export const MagazineHighlights = ({ items }: Props) => {
  const { t } = useTranslation();
  const isDesktop = useBreakpoint('sm');
  const resolveRoute = useRouteResolver();

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Wrapper>
      {isDesktop && (
        <BlurImage
          alt={'stain'}
          fill
          objectFit={'contain'}
          objectPosition={'top center'}
          src={'/images/bg-center.png'}
        />
      )}

      <Grid>
        <Title
          as={'h2'}
          data-scroll={'fade-in-up'}
          data-scroll-offset={'25%'}
          style={{ gridArea: 'title' }}
        >
          {t('home:magazine.title')}
        </Title>

        <Text
          as={'h3'}
          data-scroll={'fade-in-up'}
          data-scroll-animation-delay={100}
          data-scroll-offset={'20%'}
          style={{ gridArea: 'lead' }}
          variant={'h1'}
        >
          {t('home:magazine.lead')}
        </Text>

        <ViewAllButton
          data-scroll={'fade-in-up'}
          data-scroll-animation-delay={100}
          data-scroll-offset={'20%'}
          href={resolveRoute('magazine')}
        >
          {t('common:actions.viewAll')}
        </ViewAllButton>

        <ListItems>
          {items?.map(
            ({ highlightImage, id, publishDate, slug, title }, index) => (
              <ListItem
                data-scroll={'fade-in-up'}
                data-scroll-animation-delay={(index + 1) * 100}
                gridArea={`item${index}`}
                isLast={items?.length > 1 && items?.length === index + 1}
                key={id}
              >
                <ListItemLink href={resolveRoute('magazineDetails', { slug })}>
                  {index === 0 && (
                    <ItemImage>
                      {highlightImage && (
                        <Image
                          alt={title}
                          fill
                          src={highlightImage}
                        />
                      )}
                    </ItemImage>
                  )}

                  <ItemGrid>
                    <span>
                      <ItemDate>{formatDate(publishDate)}</ItemDate>

                      <Text variant={'h5'}>{title}</Text>
                    </span>

                    <Arrow
                      icon={arrowRightIcon}
                      size={'48px'}
                    />
                  </ItemGrid>
                </ListItemLink>
              </ListItem>
            )
          )}
        </ListItems>
      </Grid>
    </Wrapper>
  );
};
