/**
 * Module dependencies,
 */

import { DescriptiveSection } from 'src/components/layout/descriptive-section';
import { GetStaticProps, NextPage } from 'next';
import { Header } from 'src/components/layout/homepage/header';
import { HomePageContent } from 'src/types/page-content';
import { ImageGrid } from 'src/components/layout/section/image-grid';
import { Locale } from 'i18n-routes';
import { Magazine } from 'src/types/magazine';
import { MagazineHighlights } from 'src/components/layout/homepage/magazine-highlights';
import { SEO } from 'src/components/core/seo';
import { SSGProps } from 'src/types/app';
import { Services } from 'src/components/layout/section/services';
import { TeamMember } from 'src/types/team-member';
import { TeamSection } from 'src/components/layout/section/team';
import { Testimonial } from 'src/types/testimonial';
import { Testimonials } from 'src/components/layout/section/testimonials';
import { TriggerNavbarTheme } from 'src/components/layout/navbar/trigger-navbar-theme';
import { getHomePage } from 'src/requests/home';
import { getMagazine } from 'src/requests/magazine';
import { getTeam } from 'src/requests/team';
import { getTestimonials } from 'src/requests/testimonials';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useBreakpoint } from '@untile/react-components';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useTranslation } from 'next-i18next';

/**
 * `Props` type.
 */

type Props = {
  data: {
    content: HomePageContent;
    magazineHighlights: Magazine[];
    teamMembers: TeamMember[];
    testimonials: Testimonial[];
  };
};

/**
 * `Home` page.
 */

const Home: NextPage<Props> = ({ data }) => {
  const { t } = useTranslation('home');
  const isDesktop = useBreakpoint('xs');
  const resolveRoute = useRouteResolver();

  return (
    <>
      <SEO
        description={data.content.descriptionSeo}
        title={data.content.titleSeo}
      />

      <TriggerNavbarTheme theme={'dark'} />

      <Header
        buttonLabel={data.content.linkLabel}
        buttonUrl={data.content.linkUrl}
        fullVideoUrl={data.content.videoUrl}
        lead={data.content.anteTitle}
        previewVideoUrl={data.content.videoFile}
        title={data.content.title}
        videoImage={data.content.image}
      />

      <TriggerNavbarTheme theme={'light'} />

      <DescriptiveSection
        action={{
          label: t(
            `common:actions.bookConsultation${!isDesktop ? 'Mobile' : ''}`
          ),
          url: resolveRoute('appointment')
        }}
        content={[
          {
            description: t('home:whoWeAre.description'),
            lead: t('home:whoWeAre.lead'),
            title: t('home:whoWeAre.title')
          }
        ]}
      />

      <Services />

      <TriggerNavbarTheme theme={'dark'} />

      <TeamSection members={data.teamMembers} />

      <TriggerNavbarTheme theme={'light'} />

      <Testimonials items={data.testimonials} />

      <ImageGrid
        centerImageUrl={data.content.imageGrid2}
        leftImageUrl={data.content.imageGrid1}
        rightImageUrl={data.content.imageGrid3}
      />

      <MagazineHighlights items={data?.magazineHighlights} />
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = async ({ locale }) => {
  const [content, magazineHighlights, teamMembers, testimonials] =
    await Promise.all([
      getHomePage(locale as Locale),
      getMagazine(locale as Locale, { filter: { highlight: true }, limit: 4 }),
      getTeam(locale as Locale),
      getTestimonials(locale as Locale)
    ]);

  return {
    props: {
      data: { content, magazineHighlights, teamMembers, testimonials },
      navbarTheme: 'dark',
      ...(await serverSideTranslations(locale as Locale, ['common', 'home']))
    },
    revalidate: 60
  };
};

/**
 * Export `Home` component.
 */

export default Home;
