/**
 * Module dependencies.
 */

import { CardSlide } from 'src/components/core/carousel';
import { GalleryCarousel } from './gallery-carousel';
import { Locale } from 'i18n-routes';
import { getLocalizedCategoryByServiceSlug } from 'src/core/utils/routes';
import { orderedServiceAreaEnum } from 'src/core/constants/enums';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import camelCase from 'lodash/camelCase';

/**
 * `Services` component.
 */

export function Services(props: { className?: string }) {
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const resolveRoute = useRouteResolver();
  const carouselSlides = orderedServiceAreaEnum.map(slug => ({
    imageUrl: `/images/services/${slug}.jpeg`,
    subCategoryUrl: resolveRoute('services', {
      subCategory: getLocalizedCategoryByServiceSlug(locale as Locale, slug)
    }),
    title: t(`service.subCategory.${camelCase(slug)}`)
  }));

  return (
    <GalleryCarousel
      buttonLabel={t('sections.services.viewAll')}
      buttonUrl={resolveRoute('allServices')}
      className={props?.className}
      slidesLength={carouselSlides.length}
      title={t('sections.services.title')}
    >
      {carouselSlides.map(({ imageUrl, subCategoryUrl, title }, index) => (
        <CardSlide
          data-scroll={'clip-left'}
          data-scroll-animation-delay={index * 400}
          data-scroll-offset={'20%'}
          href={subCategoryUrl}
          imageUrl={imageUrl}
          key={imageUrl}
          title={title}
        />
      ))}
    </GalleryCarousel>
  );
}
